import React from "react"


const NotFoundPage = () => (
    <>
        <h1>Theres no such page</h1>
    </>
)


export default NotFoundPage
